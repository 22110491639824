@import 'src/base';

@import 'src/base';

.reset-password {
    padding: 0;
    min-height: 100vh;
    display: grid;
    align-items: stretch;
    //   background-color: $background-content;
    background: linear-gradient(300deg, rgba(67, 181, 208, 1) 0%, rgba(92, 47, 156, 1) 65%);

    .reset-password-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-img {
        width: 150px;
    }
    .reset-password-form {
        @extend .rounded; //   background-color: lighten($primary-color, 30%);
        text-align: center;
        background-color: $background-content;
        box-shadow: 0px 0px 10px #7c7c7c7e;
        padding: 30px 40px;
    }
    .reset-password-buttons-container {
        overflow: hidden;
        margin-top: 1em;
        button{
            margin-top: 10px;
        }
    }
    .forgot-pass-btn {
        padding: 2px;
        float: right;
    }
    .no-account {
        color: $white;
        padding: 2px;
        float: right;
    }
    .reset-password-banner-container {
        display: grid;
        align-items: center;
        text-align: center;
    }
    .reset-password-banner {
        width: 100%;
    }

    .form-item {
        color: white;
    }
    .btn-goto-register {
        @extend .button;
    }

    @media screen and (max-width: $breakpoint-md) {
        .reset-password-header {
            padding: 0 20px;
        }
        .header-img {
            width: 100px;
        }
        .reset-password-form {
            padding: 1rem;
        }
        .reset-password-banner-container {
            align-items: flex-start;
        }
        .not-account-text {
            display: none;
        }
    }
}
