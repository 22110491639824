@import 'src/base';

.welcome {
    padding: 0;
    min-height: 100vh;
    height: 50%;
    display: flex;
    justify-content: space-around;
    //   background-color: $background-content;
    background: linear-gradient(300deg, rgba(67, 181, 208, 1) 0%, rgba(92, 47, 156, 1) 65%);

    Button{
        height: 100px;
        margin-top: 10px;
    }
}